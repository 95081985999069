import { Grid, Typography } from "@mui/material";
import React from "react";
import AnimatingNumber from "../../../../Components/AnimatingNumber/animation-number";
import OverlayShade from "../OverlayShade";

const LatestIdeaCardDummy = ({
  isAuthenticated,
  mediumHeaderFontSize,
  smallParagraphFontSize,
  dateOptions,
  paragraphFontSize,
}) => {
  return (
    <OverlayShade isAuthenticated={isAuthenticated}>
      <Grid
        sx={{
          userSelect: "none",
        }}
        container
        display="flex"
        alignItems={"center"}
        justifyContent="center"
        p={1}
        spacing={1}
        py={2}
      >
        <Grid item xs={12}>
          <Typography
            fontWeight={600}
            lineHeight={1}
            fontSize={mediumHeaderFontSize}
            textTransform="capitalize"
          >
            Article Name
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            display="flex"
            justifyContent="start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography
                fontSize={smallParagraphFontSize}
                color="green"
                display="flex"
              >
                ₹###
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={smallParagraphFontSize}>+INR</Typography>
            </Grid>
            <Grid item display="flex" fontSize={smallParagraphFontSize}>
              ₹
              <AnimatingNumber value="###" />
            </Grid>
            <Grid item display="flex" fontSize={smallParagraphFontSize}>
              (₹
              <AnimatingNumber value="###" />
              %)
            </Grid>
            <Grid item display="flex">
              <Typography
                fontSize={smallParagraphFontSize}
                lineHeight={1}
                textTransform="capitalize"
              >
                {new Date().toLocaleDateString("en-US", dateOptions)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight={300}
            color="GrayText"
            lineHeight={1.2}
            textTransform="capitalize"
            fontSize={paragraphFontSize}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quod,
            vel fugit voluptas ut dolorem eligendi vitae in, soluta rerum, sunt
            odio dolor ullam dolorum dolore quasi rem quaerat reprehenderit!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            fontWeight={600}
            lineHeight={1}
            textTransform="capitalize"
          >
            published on {new Date().toLocaleDateString("en-US", dateOptions)}
          </Typography>
        </Grid>
      </Grid>
    </OverlayShade>
  );
};

export default LatestIdeaCardDummy;
