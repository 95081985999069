import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Sad from "../../Assets/sad-face.png";
import Button from "../../Components/Button/Button";
import { SubscriptionScheduleContext } from "../../Contexts/SubscriptionSchedule";
import { subscriptions } from "../../utils/routingConstants";
import "./style.scss";

export default function Sorry() {
  const history = useHistory();
  const { activeSchedule } = useContext(SubscriptionScheduleContext);
  useEffect(() => {
    if (!activeSchedule) {
      history.push(subscriptions);
    }
  }, [activeSchedule, history]);
  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center justify-content-center h-100 text-center">
        <div className="d-flex align-items-center">
          <h2 className=" fs-95 ">Sorry</h2>
          <div className="d-flex justify-content-center smile-icon">
            <img src={Sad} alt="icon" />
          </div>
        </div>
        <h6 className=" fs-30 mt-1 semi-font">
          Unfortunately your payment failed.
          <br />
          You can go back and check again.
        </h6>
        <Grid
          container
          display="flex"
          justifyContent="center"
          mt={2}
          spacing={2}
        >
          <Grid item>
            <Button onClick={() => history.goBack()} text="Go back" />
          </Grid>
          <Grid item>
            <Button onClick={() => history.push("/")} text="Home" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
