import React from "react";

const TwitterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9379 10.392L21.5324 1.5H19.7324L13.1399 9.2205L7.87188 1.5H1.79688L9.76188 13.176L1.79688 22.5H3.59688L10.5599 14.346L16.1234 22.5H22.1984L13.9379 10.392ZM11.4734 13.278L10.6664 12.1155L4.24488 2.865H7.00938L12.1904 10.3305L12.9974 11.493L19.7339 21.198H16.9694L11.4734 13.278Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TwitterIcon;
