import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SubscriptionScheduleContext } from "../../Contexts/SubscriptionSchedule";
import { useInitiatePaytmPaymentMutation } from "../../redux/PaytmAPI";
import { useGetPlanByIdQuery } from "../../redux/PlansAPI";
import {
  useGetVerificationMutation,
  useInitiatePaymentMutation,
} from "../../redux/RazorpayAPI";
import { useGetReferralByCodeMutation } from "../../redux/ReferralsAPI";
import { subscriptions } from "../../utils/routingConstants";
import AuthContext from "../Auth/AuthContext";
import "./style.scss";
const REACT_APP_PAYTM_MID = process.env.REACT_APP_PAYTM_MID;
const REACT_APP_RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;

const usePlanSubscription = () => {
  const { isAuthenticated, fetchMe, isAdmin, user } = useContext(AuthContext);
  const history = useHistory();
  const { planId } = useParams();

  const [verifyCode, { data: fetchedReferral }] =
    useGetReferralByCodeMutation();
  const { data: fetchedPlan } = useGetPlanByIdQuery({ id: planId });
  const [initRazorpayPayment, { data }] = useInitiatePaymentMutation();
  const [verifyPayment, { data: verification }] = useGetVerificationMutation();
  const [referralCode, setReferralCode] = useState("");
  const [verifyingRef, setVerifyingRef] = useState(false);
  const [verifiedRef, setVerifiedRef] = useState(false);
  const [referral, setReferral] = useState();

  const [plan, setPlan] = useState(fetchedPlan);
  const [order, setOrder] = useState(data);
  const [termsChecked, setTermsChecked] = useState(false);
  const [checkboxBorderColor, setCheckboxBorderColor] = useState(
    "terms-checkbox-grey",
  );

  const launchPayment = async (paymentInitCallback) => {
    if (termsChecked) {
      if (isAuthenticated) {
        try {
          await paymentInitCallback();
          setCheckboxBorderColor("terms-checkbox-grey");
        } catch (err) {}
      } else {
        history.push("/login");
      }
    } else {
      setCheckboxBorderColor("terms-checkbox-error");
    }
  };

  const launchRazorpayPayment = async () => {
    launchPayment(() =>
      initRazorpayPayment({
        planId,
        referralCode: (referral && referral.code) || undefined,
        amountToPay: cardFields.totalAmount,
      }),
    );
  };
  const handleChange = useCallback((e) => {
    setVerifyingRef(false);
    setReferralCode(e.target.value);
  }, []);
  const onCheck = (e) => {
    setTermsChecked(e.target.checked);
    e.target.checked && setCheckboxBorderColor("terms-checkbox-grey");
  };
  const handleVerify = useCallback(async () => {
    await verifyCode({ code: referralCode });
  }, [referralCode, verifyCode]);
  const cardFields = useMemo(() => {
    const planAmount = (plan && Number(plan.amount)) || 0;
    const discount = Math.floor(
      (((referral && Number(referral.discount)) || 0) * planAmount) / 100,
    );
    const tax = (plan && Number(plan.taxAmount)) || 0;
    const totalAmount = Math.floor(planAmount + tax - discount);
    return {
      planAmount,
      discount,
      tax,
      totalAmount,
    };
  }, [plan, referral]);
  useEffect(() => {
    if (fetchedReferral) {
      if (
        fetchedReferral.error &&
        !(
          fetchedReferral.error === "NO REFERRAL MATCHING THIS CODE!" ||
          fetchedReferral.error === "CAN'T USE ONE SELFE'S CODE FOR HIMSELF!"
        )
      )
        history.push("/login");
      else setReferral(fetchedReferral.payload);
    }
  }, [fetchedReferral, history]);
  useEffect(() => {
    if (fetchedReferral) {
      setVerifyingRef(true);
      if (fetchedReferral.success) setVerifiedRef(true);
      else setVerifiedRef(false);
    } else setVerifiedRef(false);
  }, [fetchedReferral]);
  useEffect(() => {
    if (fetchedPlan && fetchedPlan.payload) {
      setPlan(fetchedPlan.payload);
      if (!isAdmin && !fetchedPlan.payload.isActive) {
        history.push("/subscriptions");
      }
    }
  }, [fetchedPlan, history, isAdmin]);
  useEffect(() => {
    data && data.payload && setOrder(data.payload);
  }, [data]);
  useEffect(() => {
    if (order && plan) {
      const prefill = {};
      if (user.email) prefill.email = user.email;
      if (user.name) prefill.name = user.name;
      if (user.phone) prefill.contact = `+${user.phone}`;
      const options = {
        key: REACT_APP_RAZORPAY_KEY,
        description: plan.description.slice(0, 254),
        order_id: order.orderId,
        config: {
          display: {
            hide: [
              {
                method: "upi",
              },
            ],

            preferences: {
              show_default_blocks: true,
            },
          },
        },
        handler: async (response) => {
          try {
            await verifyPayment({
              ...response,
              planId: plan.id,
              referralCode: (referral && referral.code) || undefined,
            });
          } catch (error) {}
        },
        theme: {
          color: "#000000",
        },
      };
      if (prefill.email || prefill.name || prefill.contact)
        options.prefill = prefill;
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    }

    setOrder();
  }, [plan, planId, order, verifyPayment, referral, user]);
  useEffect(() => {
    if (
      verification &&
      verification.success &&
      verification.message === "Successful"
    ) {
      fetchMe();
      history.push("/thank-you");
    }
  }, [verification, history, fetchMe]);

  // paytm payment

  const [initPaytmPayment, { data: paytmInit }] =
    useInitiatePaytmPaymentMutation();
  const [txnToken, setTxnToken] = useState();
  const [paytmOrderId, setPaytmOrderId] = useState();
  useEffect(() => {
    paytmInit &&
      paytmInit.body &&
      paytmInit.body.txnToken &&
      setTxnToken(paytmInit.body.txnToken);
  }, [paytmInit]);
  const onScriptLoad = useCallback(() => {
    const config = {
      root: "",
      style: {
        themeBackgroundColor: "#000",
        themeColor: "#fff",
        headerBackgroundColor: "#000000",
        headerColor: "#FFFFFF",
      },
      flow: "DEFAULT",
      payMode: {
        order: ["UPI", "CARD", "EMI", "NB", "BALANCE"],
      },
      data: {
        orderId: paytmOrderId,
        token: txnToken,
        tokenType: "TXN_TOKEN",
        amount: `${cardFields.planAmount}`,
      },
      handler: {
        notifyMerchant: (eventName, data) => {
          console.log("notifyMerchant handler function called");
          console.log("eventName => ", eventName);
          console.log("data => ", data);
        },
      },
    };
    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.onLoad(() => {
        window.Paytm.CheckoutJS.init(config)
          .then(() => {
            window.Paytm.CheckoutJS.invoke();
          })
          .catch((error) => {
            console.log("error => ", error);
          });
      });
    }
  }, [cardFields, paytmOrderId, txnToken]);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/${REACT_APP_PAYTM_MID}.js`;
    script.onload = onScriptLoad;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [onScriptLoad]);

  const launchPaytmPayment = () => {
    const orderId = `ORDERID_${new Date().getTime()}`.replace(/\s/g, "");
    setPaytmOrderId(orderId);
    launchPayment(() =>
      initPaytmPayment({
        planId,
        referralCode: (referral && referral.code) || undefined,
        orderId,
      }),
    );
  };

  const { activeSchedule } = useContext(SubscriptionScheduleContext);
  useEffect(() => {
    if (!activeSchedule) {
      history.push(subscriptions);
    }
  }, [activeSchedule, history]);
  return {
    verifyingRef,
    verifiedRef,
    checkboxBorderColor,
    launchRazorpayPayment,
    launchPaytmPayment,
    handleChange,
    onCheck,
    handleVerify,
    // initPaytmPayment,
    plan,
    termsChecked,
    cardFields,
    referralCode,
  };
};
export default usePlanSubscription;
