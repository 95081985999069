import React from "react";

const LeftArrowIcon = ({ disbaled }) => {
  return (
    <svg
      width="27"
      height="21"
      viewBox="0 0 27 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7273 1.687C10.8176 1.59153 10.8881 1.47923 10.9349 1.3565C10.9817 1.23377 11.0039 1.10302 11.0002 0.971721C10.9965 0.840419 10.9669 0.711134 10.9133 0.591248C10.8596 0.471362 10.7828 0.363223 10.6873 0.273005C10.5919 0.182787 10.4796 0.112257 10.3568 0.0654411C10.2341 0.0186256 10.1034 -0.00355848 9.97206 0.000155861C9.84076 0.00387021 9.71147 0.03341 9.59159 0.0870888C9.4717 0.140767 9.36356 0.217534 9.27334 0.313005L0.773343 9.313C0.597806 9.49867 0.5 9.74449 0.5 10C0.5 10.2555 0.597806 10.5013 0.773343 10.687L9.27334 19.688C9.36296 19.7856 9.47108 19.8643 9.59141 19.9198C9.71173 19.9752 9.84187 20.0062 9.97427 20.0109C10.1067 20.0156 10.2387 19.9939 10.3626 19.9472C10.4866 19.9004 10.6 19.8295 10.6963 19.7386C10.7927 19.6476 10.8699 19.5384 10.9237 19.4173C10.9775 19.2963 11.0067 19.1657 11.0095 19.0333C11.0124 18.9008 10.989 18.7691 10.9405 18.6458C10.8921 18.5225 10.8196 18.4101 10.7273 18.315L2.87534 10L10.7273 1.687Z"
        fill={disbaled ? "#7C7C7C" : "currentColor"}
        fillOpacity="0.5"
      />
      <path
        d="M3 10.3501H26"
        stroke={disbaled ? "#7C7C7C" : "currentColor"}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LeftArrowIcon;
