import React from "react";

const WhatsAppIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0587 2.83406C9.32375 2.83406 3.01958 9.13823 3.01958 16.8732C3.01958 19.3524 3.67125 21.7607 4.88958 23.8857L2.90625 31.1674L10.3438 29.2124C12.3979 30.3316 14.7071 30.9266 17.0587 30.9266C24.7937 30.9266 31.0979 24.6224 31.0979 16.8874C31.0979 13.1332 29.6388 9.60573 26.9896 6.95656C25.6906 5.64488 24.1435 4.60485 22.4386 3.89707C20.7336 3.1893 18.9048 2.82794 17.0587 2.83406ZM17.0729 5.19989C20.1896 5.19989 23.1079 6.41823 25.3179 8.62823C26.4022 9.71271 27.2619 11.0005 27.8478 12.4177C28.4336 13.8349 28.7341 15.3538 28.7321 16.8874C28.7321 23.3191 23.4904 28.5466 17.0587 28.5466C14.9621 28.5466 12.9079 27.9941 11.1229 26.9174L10.6979 26.6766L6.27792 27.8382L7.45375 23.5316L7.17042 23.0782C6.00102 21.2201 5.38211 19.0687 5.38542 16.8732C5.39958 10.4416 10.6271 5.19989 17.0729 5.19989ZM12.0862 10.3849C11.8596 10.3849 11.4771 10.4699 11.1512 10.8241C10.8396 11.1782 9.91875 12.0424 9.91875 13.7566C9.91875 15.4849 11.1796 17.1424 11.3354 17.3832C11.5337 17.6241 13.8287 21.1657 17.3562 22.6674C18.1921 23.0499 18.8437 23.2624 19.3537 23.4182C20.1896 23.6874 20.9546 23.6449 21.5637 23.5599C22.2437 23.4607 23.6321 22.7099 23.9296 21.8882C24.2271 21.0666 24.2271 20.3724 24.1421 20.2166C24.0429 20.0749 23.8162 19.9899 23.4621 19.8341C23.1079 19.6357 21.3796 18.7857 21.0679 18.6724C20.7421 18.5591 20.5438 18.5024 20.2746 18.8424C20.0479 19.1966 19.3679 19.9899 19.1696 20.2166C18.9571 20.4574 18.7587 20.4857 18.4187 20.3157C18.0504 20.1316 16.9171 19.7632 15.5854 18.5732C14.5371 17.6382 13.8429 16.4907 13.6304 16.1366C13.4604 15.7966 13.6162 15.5841 13.7862 15.4282C13.9421 15.2724 14.1687 15.0174 14.3104 14.8049C14.4946 14.6066 14.5512 14.4507 14.6646 14.2241C14.7779 13.9832 14.7212 13.7849 14.6362 13.6149C14.5512 13.4591 13.8429 11.7024 13.5454 11.0082C13.2621 10.3282 12.9787 10.4132 12.7521 10.3991C12.5537 10.3991 12.3271 10.3849 12.0862 10.3849Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WhatsAppIcon;
