import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import FaqCard from "../../Components/Accordion Card/FaqCard";
import CustomAccordion from "../../Components/Accordion/CustomAccordion";
import Button from "../../Components/Button/Button";
import SlideUp from "../../Components/SlideUp";
import faqs from "./faqs.json";

const REACT_APP_EMAIL_LINK = process.env.REACT_APP_EMAIL_LINK;

export default function Faq() {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );

  const [expanded, setExpanded] = useState(false);
  const [expansion, setExpansion] = useState(3);

  const handleMore = () => {
    setExpansion((prev) => prev + 3);
  };
  const handleLess = () => {
    setExpansion((prev) => (prev >= 6 ? prev - 3 : prev - (prev % 3)));
  };
  useEffect(() => {
    if (expansion <= 3) setExpanded(false);
    else setExpanded(true);
  }, [expansion]);
  const faqsToShow = useMemo(() => faqs?.slice(0, expansion), [expansion]);
  return (
    <Box>
      <Box
        height={!isSmDown && "95vh"}
        my={isSmDown && "10vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
                pl={isMdDown ? 0 : 10}
              >
                <SlideUp capitalize>Frequently</SlideUp>
              </Typography>{" "}
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
                pl={isMdDown ? 0 : 10}
              >
                <SlideUp capitalize>asked questions</SlideUp>
              </Typography>{" "}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        height={!isSmDown && "100vh"}
        my={isSmDown && "10vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={2} md={2} sm={3}>
              <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                Overview
              </Typography>
            </Grid>
            <Grid item lg={8} md={10} sm={9}>
              <Typography
                fontSize={paragraphFontSize}
                fontWeight={300}
                lineHeight={1.2}
              >
                <SlideUp>
                  In order to simply and get most of your queries cleared we
                  have prepared a list of questions which have repeatedly asked
                  by darkhorsestocks followers over the time. However if you
                  have any other question which is not covered in the below list
                  feel free to write us at
                </SlideUp>
                <SlideUp>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="line-animation-3"
                    style={{
                      color: "#838282",
                      textTransform: "none !important",
                    }}
                    href={REACT_APP_EMAIL_LINK}
                  >
                    Support@darkhorsestocks.in
                  </a>
                </SlideUp>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY="10vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex" justifyContent="center">
            {faqsToShow &&
              faqsToShow.length !== 0 &&
              faqsToShow.map((faq, idx) => (
                <Grid item key={idx} xs={12}>
                  <CustomAccordion
                    right={faq.question}
                    card={<FaqCard content={faq.answer} />}
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                rowSpacing={8}
                display="flex"
                justifyContent="center"
              >
                {faqs?.length > expansion && (
                  <Grid item>
                    <Button onClick={handleMore} text="Show More" />
                  </Grid>
                )}
                {expanded && (
                  <Grid item>
                    <Button onClick={handleLess} text="Show Less" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
