import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../Components/Button/Button";
import SlideUp from "../../Components/SlideUp";

export default function Categories() {
  const history = useHistory();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const headerFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "30px" : "45px") : "75px"),
    [isMdDown, isSmDown],
  );
  const paragraphFontSize = useMemo(
    () => (isMdDown ? (isSmDown ? "20px" : "22px") : "28px"),
    [isMdDown, isSmDown],
  );
  const handleClick = useCallback(
    (categoryName) => {
      if (categoryName)
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}category/id-by-name/${categoryName}`,
          )
          .then((res) => {
            history.push(`/categories-individual/${res.data.id}`);
          })
          .catch((e) => history.push("/"));
      else history.push("/");
    },
    [history],
  );

  return (
    <Box>
      <Box
        height={!isSmDown && "95vh"}
        my={isSmDown && "10vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={12} md={12} sm={12} xs={12} overflow="hidden">
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
                pl={isMdDown ? 0 : 10}
              >
                <SlideUp capitalize>Bifurcation</SlideUp>
              </Typography>
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
                pl={isMdDown ? 0 : 10}
              >
                <SlideUp capitalize>based on</SlideUp>
              </Typography>
              <Typography
                fontWeight={600}
                lineHeight={1.2}
                fontSize={headerFontSize}
                pl={isMdDown ? 0 : 10}
                pb={1}
              >
                <SlideUp capitalize>categories</SlideUp>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <img
          src={
            "https://darkhorsestocks.com/wp-content/uploads/2024/01/library_4x.jpg"
          }
          alt="Hero"
          className="hero-img"
        />
      </Box>
      <Box
        height={!isSmDown && "100vh"}
        my={isSmDown && "10vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box width={isSmDown ? "90vw" : "75vw"} display="flex">
          <Grid container spacing={2} display="flex">
            <Grid item lg={2} md={2} sm={3}>
              <Typography fontSize={20} fontWeight={600} lineHeight={1.2}>
                Overview
              </Typography>
            </Grid>
            <Grid item lg={8} md={10} sm={9}>
              <Grid container spacing={2} display="flex" flexDirection="column">
                <Grid item>
                  <Typography
                    fontSize={paragraphFontSize}
                    fontWeight={500}
                    lineHeight={1.2}
                  >
                    <SlideUp>
                      Companies at darkhorsestocks are bifurcated into various
                      different categories. Classification of these stocks in to
                      various categories is explained below.
                    </SlideUp>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY={isSmDown ? "10vh" : "25vh"}
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Darkhorsestocks</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  Darkhorsestocks is our prime category where every week one
                  stock article is presented with all the relevant and
                  introductory information. The company presented here is
                  selected based on various suitable parameters and growth
                  prospects we anticipate from the large pool of data . Normally
                  companies suggested in this category are fundamentally strong
                  and have proven consistent track record of growth. Some of the
                  companies suggested under this category in past have delivered
                  Phenomenal Returns.
                </SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginY={2}
            display="flex"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <Button
                text="View all"
                onClick={() => handleClick("Darkhorsestocks")}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY={isSmDown ? "10vh" : "25vh"}
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Infographics</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  This is the newest thing started by darkhorsestocks where we
                  try to make investing more interactive and interesting. With
                  Inforgraphic we present one old article which has already been
                  suggested previously and in our view the article has missed
                  the user attention or we would like users to focus more on the
                  particular article as we belive the same could have a good
                  potential upside possible. With Inforgraphic we try to cover
                  the maximum and most important points in a summarized manner
                  which would interest users to dig deeper into the stock.
                  However we also advice users to read full research report for
                  the said article presented via inforgraphic.
                </SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginY={2}
            display="flex"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_INSTAGRAM_LINK}
              >
                <Button text="Instagram" />
              </a>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY={isSmDown ? "10vh" : "25vh"}
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Holding company</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  Holding company are those companies which hold the shares of
                  other companies. They are created specifically for the purpose
                  of holding investments, creating layers of holding which may
                  be feasible for tax saving purposes. Many a times the
                  investment held by these companies are valued even more than
                  the market cap of the holding companies but they are held with
                  a view to never sell them.
                </SlideUp>
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  So, holding companies can be considered as dead investments
                  since the investments are subject to realization if any over
                  very very long period. Therefore chances of holding companies
                  delivering any meaning returns are negligible but they are
                  worth knowing about or having I portfolio if you have a long
                  term view of 5-10 years.
                </SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginY={2}
            display="flex"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <Button
                text="View all"
                onClick={() => handleClick("Holding company")}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        marginY={isSmDown ? "10vh" : "25vh"}
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={isSmDown ? "85vw" : "75vw"}>
          <Grid
            container
            overflow="hidden"
            display="flex"
            flexDirection="column"
            mb={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={600}
                lineHeight={1.5}
                fontSize={headerFontSize}
              >
                <SlideUp capitalize>Penny company</SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            spacing={5}
          >
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  As the name implies Incognito means hidden , is our category
                  for Microcap/ Penny company. In India accounts are prepared in
                  accordance with Accounting Standards which now are merged with
                  newly introduced Indian Accounting Standards ( Ind AS).
                  Normally accounts are prepared based on the concept of
                  historical cost. As a result the Assets , Investments etc are
                  presented based on the Lower of Fair Value or Historical cost
                  which has a huge impact on the fundamental value of the
                  company. Land for instance will always be undervalued under
                  this concept of historical price base accounting.
                </SlideUp>
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  Although under this category the articles presented do posses
                  enormous value but have failed to deliver any meaning full
                  returns. In fact this category has turned out to be failure in
                  terms of the growth or returns since most of the articles
                  presented under this category have failed to deliver any
                  meaning full returns.
                </SlideUp>
              </Typography>
            </Grid>
            <Grid item lg={8} md={12} sm={12}>
              <Typography
                fontWeight={300}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
              >
                <SlideUp>
                  This articles are suggested for informational purpose only.
                </SlideUp>
              </Typography>
              <Typography
                fontWeight={600}
                fontSize={paragraphFontSize}
                lineHeight={1.2}
                color="red"
              >
                <SlideUp>
                  Investing in this articles is extremely risky and could lead
                  to complete loss of capital.
                </SlideUp>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginY={2}
            display="flex"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <Button
                text="View all"
                onClick={() => handleClick("Penny company")}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
