import React, { useContext, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { useHistory } from "react-router-dom";

import Card from "../Card/Card";

import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import SecImage from "../../Assets/image section.png";
import AuthContext from "../../Screens/Auth/AuthContext";
import SliderArrows from "../SliderArrows/SliderArrows";
const HomePageSlider = (props) => {
  const { homepageSection } = props;
  const { isAuthenticated, paid } = useContext(AuthContext);
  const data =
    homepageSection.companyList ||
    homepageSection.sectors ||
    homepageSection.tags ||
    homepageSection.categories;
  const history = useHistory();
  const owlRef = useRef(null);
  const gotoPrev = () => {
    owlRef && owlRef.current && owlRef.current.prev();
  };
  const gotoNext = () => {
    owlRef && owlRef.current && owlRef.current.next();
  };
  const [viewMorePath, setViewMorePath] = useState();
  const [cardContentPath, setCardContentPath] = useState();
  const [cursorData, setCursorData] = useState("Read report");
  const isCompany =
    homepageSection.companiesBySector ||
    homepageSection.companiesByCategory ||
    homepageSection.companiesByTag ||
    homepageSection.isLatestCompanies ||
    homepageSection.isTopGainers ||
    homepageSection.isTopLosers;
  useEffect(() => {
    if (homepageSection.companiesBySector) {
      setViewMorePath(`/companies/sector/${homepageSection.sectorId}`);
      setCardContentPath("/blog");
    }
    if (homepageSection.companiesByCategory) {
      setViewMorePath(`/companies/category/${homepageSection.categoryId}`);
      setCardContentPath("/blog");
    }
    if (homepageSection.companiesByTag) {
      setViewMorePath(`/companies/tag/${homepageSection.tagId}`);
      setCardContentPath("/blog");
    }
    if (homepageSection.isAllTags) {
      setCursorData("Explore tag");
      setViewMorePath(`/tags`);
      setCardContentPath("/tag");
    }
    if (homepageSection.isAllSectors) {
      setCursorData("Explore sector");
      setViewMorePath(`/sectors`);
      setCardContentPath("/sector-page");
    }
    if (homepageSection.isAllCategories) {
      setCursorData("Explore category");
      setViewMorePath(`/categories`);
      setCardContentPath("/categories-individual");
    }
    if (homepageSection.isLatestCompanies) {
      setViewMorePath(`/special-list/isLatestCompanies`);
      setCardContentPath("/blog");
    }
    if (homepageSection.isTopGainers) {
      setViewMorePath(`/special-list/isTopGainers`);
      setCardContentPath("/blog");
    }
    if (homepageSection.isTopLosers) {
      setViewMorePath(`/special-list/isTopLosers`);
      setCardContentPath("/blog");
    }
  }, [homepageSection]);
  const viewMoreClickHandler = () => {
    if (isAuthenticated) history.push(viewMorePath);
    else history.push("/login");
  };
  const onClickHandler = (id) => {
    if (isAuthenticated) history.push(`${cardContentPath}/${id}`);
    else history.push("/login");
  };
  const options = {
    items: 3,
    nav: false,
    dots: false,
    margin: 30,
    loop: false,
    autoplay: false,
    slideBy: 3,
    mouseDrag: true,
    startPosition: 1,
    responsive: {
      0: {
        items: 1,
        margin: 0,
        slideBy: 1,
      },
      600: {
        items: 2,
        slideBy: 2,
      },
      1024: {
        items: 3,
      },
    },
  };

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  if (isCompany) {
    if (!isAuthenticated)
      return (
        <div>
          {data && data.length > 5 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
              <div className="item" key={data[2].Id}>
                <Card
                  valueDate={data[2].valueDate}
                  cursorData={cursorData}
                  {...data[2]}
                  img={data[2].image || SecImage}
                  title={data[2].name}
                  price={data[2].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                  rate={data[2].rate}
                  content={data[2].description || ""}
                  date={data[2].date}
                />
              </div>
              <div className="item" key={data[3].Id}>
                <Card
                  valueDate={data[3].valueDate}
                  cursorData={cursorData}
                  {...data[3]}
                  img={data[3].image || SecImage}
                  title={data[3].name}
                  price={data[3].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                  rate={data[3].rate}
                  content={data[3].description || ""}
                  date={data[3].date}
                />
              </div>
              <div className="item" key={data[4].Id}>
                <Card
                  valueDate={data[4].valueDate}
                  cursorData={cursorData}
                  {...data[4]}
                  img={data[4].image || SecImage}
                  title={data[4].name}
                  price={data[4].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[4].blogId || data[4].Id)}
                  rate={data[4].rate}
                  content={data[4].description || ""}
                  date={data[4].date}
                />
              </div>
              <Box
                height={isMdDown && !isSmDown ? "40vh" : "50vh"}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  data-cursor-text="View More"
                  onClick={viewMoreClickHandler}
                  flexDirection="column"
                >
                  <Typography fontSize={isSmDown ? 72 : 96}>
                    <ReadMoreIcon fontSize="inherit" />
                  </Typography>
                  <Typography fontSize={24}>View More</Typography>
                </Box>
              </Box>
            </OwlCarousel>
          )}
          {data && data.length === 5 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
              <div className="item" key={data[2].Id}>
                <Card
                  valueDate={data[2].valueDate}
                  cursorData={cursorData}
                  {...data[2]}
                  img={data[2].image || SecImage}
                  title={data[2].name}
                  price={data[2].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                  rate={data[2].rate}
                  content={data[2].description || ""}
                  date={data[2].date}
                />
              </div>
              <div className="item" key={data[3].Id}>
                <Card
                  valueDate={data[3].valueDate}
                  cursorData={cursorData}
                  {...data[3]}
                  img={data[3].image || SecImage}
                  title={data[3].name}
                  price={data[3].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                  rate={data[3].rate}
                  content={data[3].description || ""}
                  date={data[3].date}
                />
              </div>
              <div className="item" key={data[4].Id}>
                <Card
                  valueDate={data[4].valueDate}
                  cursorData={cursorData}
                  {...data[4]}
                  img={data[4].image || SecImage}
                  title={data[4].name}
                  price={data[4].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[4].blogId || data[4].Id)}
                  rate={data[4].rate}
                  content={data[4].description || ""}
                  date={data[4].date}
                />
              </div>
            </OwlCarousel>
          )}
          {data && data.length === 4 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
              <div className="item" key={data[2].Id}>
                <Card
                  valueDate={data[2].valueDate}
                  cursorData={cursorData}
                  {...data[2]}
                  img={data[2].image || SecImage}
                  title={data[2].name}
                  price={data[2].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                  rate={data[2].rate}
                  content={data[2].description || ""}
                  date={data[2].date}
                />
              </div>
              <div className="item" key={data[3].Id}>
                <Card
                  valueDate={data[3].valueDate}
                  cursorData={cursorData}
                  {...data[3]}
                  img={data[3].image || SecImage}
                  title={data[3].name}
                  price={data[3].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                  rate={data[3].rate}
                  content={data[3].description || ""}
                  date={data[3].date}
                />
              </div>
            </OwlCarousel>
          )}
          {data && data.length === 3 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
              <div className="item" key={data[2].Id}>
                <Card
                  valueDate={data[2].valueDate}
                  cursorData={cursorData}
                  {...data[2]}
                  img={data[2].image || SecImage}
                  title={data[2].name}
                  price={data[2].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                  rate={data[2].rate}
                  content={data[2].description || ""}
                  date={data[2].date}
                />
              </div>
            </OwlCarousel>
          )}
          {data && data.length === 2 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
            </OwlCarousel>
          )}
          {data && data.length === 1 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
            </OwlCarousel>
          )}

          {data && data.length > 3 && (
            <SliderArrows prev={gotoPrev} next={gotoNext} />
          )}
        </div>
      );
    if (paid === "Free")
      return (
        <div>
          {data && data.length > 5 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    subscribe={true}
                    {...data[0]}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
              <div className="item" key={data[2].Id}>
                <Card
                  valueDate={data[2].valueDate}
                  cursorData={cursorData}
                  {...data[2]}
                  img={data[2].image || SecImage}
                  title={data[2].name}
                  price={data[2].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                  rate={data[2].rate}
                  content={data[2].description || ""}
                  date={data[2].date}
                />
              </div>
              <div className="item" key={data[3].Id}>
                <Card
                  valueDate={data[3].valueDate}
                  cursorData={cursorData}
                  {...data[3]}
                  img={data[3].image || SecImage}
                  title={data[3].name}
                  price={data[3].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                  rate={data[3].rate}
                  content={data[3].description || ""}
                  date={data[3].date}
                />
              </div>
              <div className="item" key={data[4].Id}>
                <Card
                  valueDate={data[4].valueDate}
                  cursorData={cursorData}
                  {...data[4]}
                  img={data[4].image || SecImage}
                  title={data[4].name}
                  price={data[4].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[4].blogId || data[4].Id)}
                  rate={data[4].rate}
                  content={data[4].description || ""}
                  date={data[4].date}
                />
              </div>
              <Box
                height={isMdDown && !isSmDown ? "40vh" : "50vh"}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  data-cursor-text="View More"
                  onClick={viewMoreClickHandler}
                  flexDirection="column"
                >
                  <Typography fontSize={isSmDown ? 72 : 96}>
                    <ReadMoreIcon fontSize="inherit" />
                  </Typography>
                  <Typography fontSize={24}>View More</Typography>
                </Box>
              </Box>
            </OwlCarousel>
          )}
          {data && data.length === 5 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    subscribe={true}
                    {...data[0]}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
              <div className="item" key={data[2].Id}>
                <Card
                  valueDate={data[2].valueDate}
                  cursorData={cursorData}
                  {...data[2]}
                  img={data[2].image || SecImage}
                  title={data[2].name}
                  price={data[2].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                  rate={data[2].rate}
                  content={data[2].description || ""}
                  date={data[2].date}
                />
              </div>
              <div className="item" key={data[3].Id}>
                <Card
                  valueDate={data[3].valueDate}
                  cursorData={cursorData}
                  {...data[3]}
                  img={data[3].image || SecImage}
                  title={data[3].name}
                  price={data[3].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                  rate={data[3].rate}
                  content={data[3].description || ""}
                  date={data[3].date}
                />
              </div>
              <div className="item" key={data[4].Id}>
                <Card
                  valueDate={data[4].valueDate}
                  cursorData={cursorData}
                  {...data[4]}
                  img={data[4].image || SecImage}
                  title={data[4].name}
                  price={data[4].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[4].blogId || data[4].Id)}
                  rate={data[4].rate}
                  content={data[4].description || ""}
                  date={data[4].date}
                />
              </div>
            </OwlCarousel>
          )}
          {data && data.length === 4 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    subscribe={true}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
              <div className="item" key={data[2].Id}>
                <Card
                  valueDate={data[2].valueDate}
                  cursorData={cursorData}
                  {...data[2]}
                  img={data[2].image || SecImage}
                  title={data[2].name}
                  price={data[2].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                  rate={data[2].rate}
                  content={data[2].description || ""}
                  date={data[2].date}
                />
              </div>
              <div className="item" key={data[3].Id}>
                <Card
                  valueDate={data[3].valueDate}
                  cursorData={cursorData}
                  {...data[3]}
                  img={data[3].image || SecImage}
                  title={data[3].name}
                  price={data[3].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                  rate={data[3].rate}
                  content={data[3].description || ""}
                  date={data[3].date}
                />
              </div>
            </OwlCarousel>
          )}
          {data && data.length === 3 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    subscribe={true}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
              <div className="item" key={data[2].Id}>
                <Card
                  valueDate={data[2].valueDate}
                  cursorData={cursorData}
                  {...data[2]}
                  img={data[2].image || SecImage}
                  title={data[2].name}
                  price={data[2].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                  rate={data[2].rate}
                  content={data[2].description || ""}
                  date={data[2].date}
                />
              </div>
            </OwlCarousel>
          )}
          {data && data.length === 2 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    subscribe={true}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
              <div className="item" key={data[1].Id}>
                <Card
                  valueDate={data[1].valueDate}
                  cursorData={cursorData}
                  {...data[1]}
                  img={data[1].image || SecImage}
                  title={data[1].name}
                  price={data[1].price}
                  isCompany={isCompany}
                  onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                  rate={data[1].rate}
                  content={data[1].description || ""}
                  date={data[1].date}
                />
              </div>
            </OwlCarousel>
          )}
          {data && data.length === 1 && (
            <OwlCarousel ref={owlRef} options={options}>
              <div className="item" key={data[0].Id}>
                <div style={{ position: "relative" }}>
                  <Card
                    valueDate={data[0].valueDate}
                    cursorData={cursorData}
                    {...data[0]}
                    subscribe={true}
                    img={data[0].image || SecImage}
                    title={data[0].name}
                    price={data[0].price}
                    isCompany={isCompany}
                    onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                    rate={data[0].rate}
                    content={data[0].description || ""}
                    date={data[0].date}
                  />
                </div>
              </div>
            </OwlCarousel>
          )}

          {data && data.length > 3 && (
            <SliderArrows prev={gotoPrev} next={gotoNext} />
          )}
        </div>
      );

    return (
      <div>
        {data && data.length > 5 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                valueDate={data[0].valueDate}
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                valueDate={data[1].valueDate}
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
            <div className="item" key={data[2].Id}>
              <Card
                valueDate={data[2].valueDate}
                cursorData={cursorData}
                {...data[2]}
                img={data[2].image || SecImage}
                title={data[2].name}
                price={data[2].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                rate={data[2].rate}
                content={data[2].description || ""}
                date={data[2].date}
              />
            </div>
            <div className="item" key={data[3].Id}>
              <Card
                valueDate={data[3].valueDate}
                cursorData={cursorData}
                {...data[3]}
                img={data[3].image || SecImage}
                title={data[3].name}
                price={data[3].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                rate={data[3].rate}
                content={data[3].description || ""}
                date={data[3].date}
              />
            </div>
            <div className="item" key={data[4].Id}>
              <Card
                valueDate={data[4].valueDate}
                cursorData={cursorData}
                {...data[4]}
                img={data[4].image || SecImage}
                title={data[4].name}
                price={data[4].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[4].blogId || data[4].Id)}
                rate={data[4].rate}
                content={data[4].description || ""}
                date={data[4].date}
              />
            </div>
            <Box
              height={isMdDown && !isSmDown ? "40vh" : "50vh"}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                data-cursor-text="View More"
                onClick={viewMoreClickHandler}
                flexDirection="column"
              >
                <Typography fontSize={isSmDown ? 72 : 96}>
                  <ReadMoreIcon fontSize="inherit" />
                </Typography>
                <Typography fontSize={24}>View More</Typography>
              </Box>
            </Box>
          </OwlCarousel>
        )}
        {data && data.length === 5 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                valueDate={data[0].valueDate}
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                valueDate={data[1].valueDate}
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
            <div className="item" key={data[2].Id}>
              <Card
                valueDate={data[2].valueDate}
                cursorData={cursorData}
                {...data[2]}
                img={data[2].image || SecImage}
                title={data[2].name}
                price={data[2].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                rate={data[2].rate}
                content={data[2].description || ""}
                date={data[2].date}
              />
            </div>
            <div className="item" key={data[3].Id}>
              <Card
                valueDate={data[3].valueDate}
                cursorData={cursorData}
                {...data[3]}
                img={data[3].image || SecImage}
                title={data[3].name}
                price={data[3].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                rate={data[3].rate}
                content={data[3].description || ""}
                date={data[3].date}
              />
            </div>
            <div className="item" key={data[4].Id}>
              <Card
                valueDate={data[4].valueDate}
                cursorData={cursorData}
                {...data[4]}
                img={data[4].image || SecImage}
                title={data[4].name}
                price={data[4].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[4].blogId || data[4].Id)}
                rate={data[4].rate}
                content={data[4].description || ""}
                date={data[4].date}
              />
            </div>
          </OwlCarousel>
        )}
        {data && data.length === 4 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                valueDate={data[0].valueDate}
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                valueDate={data[1].valueDate}
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
            <div className="item" key={data[2].Id}>
              <Card
                valueDate={data[2].valueDate}
                cursorData={cursorData}
                {...data[2]}
                img={data[2].image || SecImage}
                title={data[2].name}
                price={data[2].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                rate={data[2].rate}
                content={data[2].description || ""}
                date={data[2].date}
              />
            </div>
            <div className="item" key={data[3].Id}>
              <Card
                valueDate={data[3].valueDate}
                cursorData={cursorData}
                {...data[3]}
                img={data[3].image || SecImage}
                title={data[3].name}
                price={data[3].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                rate={data[3].rate}
                content={data[3].description || ""}
                date={data[3].date}
              />
            </div>
          </OwlCarousel>
        )}
        {data && data.length === 3 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                valueDate={data[0].valueDate}
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                valueDate={data[1].valueDate}
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
            <div className="item" key={data[2].Id}>
              <Card
                valueDate={data[2].valueDate}
                cursorData={cursorData}
                {...data[2]}
                img={data[2].image || SecImage}
                title={data[2].name}
                price={data[2].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                rate={data[2].rate}
                content={data[2].description || ""}
                date={data[2].date}
              />
            </div>
          </OwlCarousel>
        )}
        {data && data.length === 2 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                valueDate={data[0].valueDate}
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                valueDate={data[1].valueDate}
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
          </OwlCarousel>
        )}
        {data && data.length === 1 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                valueDate={data[0].valueDate}
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
          </OwlCarousel>
        )}

        {data && data.length > 3 && (
          <SliderArrows prev={gotoPrev} next={gotoNext} />
        )}
      </div>
    );
  } else
    return (
      <div>
        {data && data.length > 5 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
            <div className="item" key={data[2].Id}>
              <Card
                cursorData={cursorData}
                {...data[2]}
                img={data[2].image || SecImage}
                title={data[2].name}
                price={data[2].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                rate={data[2].rate}
                content={data[2].description || ""}
                date={data[2].date}
              />
            </div>
            <div className="item" key={data[3].Id}>
              <Card
                cursorData={cursorData}
                {...data[3]}
                img={data[3].image || SecImage}
                title={data[3].name}
                price={data[3].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                rate={data[3].rate}
                content={data[3].description || ""}
                date={data[3].date}
              />
            </div>
            <div className="item" key={data[4].Id}>
              <Card
                cursorData={cursorData}
                {...data[4]}
                img={data[4].image || SecImage}
                title={data[4].name}
                price={data[4].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[4].blogId || data[4].Id)}
                rate={data[4].rate}
                content={data[4].description || ""}
                date={data[4].date}
              />
            </div>
            <Box
              height={isMdDown && !isSmDown ? "40vh" : "50vh"}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                data-cursor-text="View More"
                onClick={viewMoreClickHandler}
                flexDirection="column"
              >
                <Typography fontSize={isSmDown ? 72 : 96}>
                  <ReadMoreIcon fontSize="inherit" />
                </Typography>
                <Typography fontSize={24}>View More</Typography>
              </Box>
            </Box>
          </OwlCarousel>
        )}
        {data && data.length === 5 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
            <div className="item" key={data[2].Id}>
              <Card
                cursorData={cursorData}
                {...data[2]}
                img={data[2].image || SecImage}
                title={data[2].name}
                price={data[2].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                rate={data[2].rate}
                content={data[2].description || ""}
                date={data[2].date}
              />
            </div>
            <div className="item" key={data[3].Id}>
              <Card
                cursorData={cursorData}
                {...data[3]}
                img={data[3].image || SecImage}
                title={data[3].name}
                price={data[3].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                rate={data[3].rate}
                content={data[3].description || ""}
                date={data[3].date}
              />
            </div>
            <div className="item" key={data[4].Id}>
              <Card
                cursorData={cursorData}
                {...data[4]}
                img={data[4].image || SecImage}
                title={data[4].name}
                price={data[4].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[4].blogId || data[4].Id)}
                rate={data[4].rate}
                content={data[4].description || ""}
                date={data[4].date}
              />
            </div>
          </OwlCarousel>
        )}
        {data && data.length === 4 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
            <div className="item" key={data[2].Id}>
              <Card
                cursorData={cursorData}
                {...data[2]}
                img={data[2].image || SecImage}
                title={data[2].name}
                price={data[2].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                rate={data[2].rate}
                content={data[2].description || ""}
                date={data[2].date}
              />
            </div>
            <div className="item" key={data[3].Id}>
              <Card
                cursorData={cursorData}
                {...data[3]}
                img={data[3].image || SecImage}
                title={data[3].name}
                price={data[3].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[3].blogId || data[3].Id)}
                rate={data[3].rate}
                content={data[3].description || ""}
                date={data[3].date}
              />
            </div>
          </OwlCarousel>
        )}
        {data && data.length === 3 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
            <div className="item" key={data[2].Id}>
              <Card
                cursorData={cursorData}
                {...data[2]}
                img={data[2].image || SecImage}
                title={data[2].name}
                price={data[2].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[2].blogId || data[2].Id)}
                rate={data[2].rate}
                content={data[2].description || ""}
                date={data[2].date}
              />
            </div>
          </OwlCarousel>
        )}
        {data && data.length === 2 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
            <div className="item" key={data[1].Id}>
              <Card
                cursorData={cursorData}
                {...data[1]}
                img={data[1].image || SecImage}
                title={data[1].name}
                price={data[1].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[1].blogId || data[1].Id)}
                rate={data[1].rate}
                content={data[1].description || ""}
                date={data[1].date}
              />
            </div>
          </OwlCarousel>
        )}
        {data && data.length === 1 && (
          <OwlCarousel ref={owlRef} options={options}>
            <div className="item" key={data[0].Id}>
              <Card
                cursorData={cursorData}
                {...data[0]}
                img={data[0].image || SecImage}
                title={data[0].name}
                price={data[0].price}
                isCompany={isCompany}
                onClick={() => onClickHandler(data[0].blogId || data[0].Id)}
                rate={data[0].rate}
                content={data[0].description || ""}
                date={data[0].date}
              />
            </div>
          </OwlCarousel>
        )}

        {data && data.length > 3 && (
          <SliderArrows prev={gotoPrev} next={gotoNext} />
        )}
      </div>
    );
};

export default HomePageSlider;
