import { gsap } from "gsap";
import MouseFollower from "mouse-follower";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Route } from "react-router-dom";
import {
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import BlogFooter from "../../Components/Blog Footer/BlogFooter";
import NavBar from "../../Components/NavBar/NavBar";
import Alert from "../../Components/NewComponents/Alert";
import CustomTable from "../../Components/NewComponents/CustomTable";
import Video from "../../Components/NewComponents/Video";
import YouTubeVideo from "../../Components/NewComponents/YouTubeVideo";
import ScrollToTop from "../../Components/ScrollToTop";
import WelcomeModal from "../../Components/WelcomeModal";
import { SubscriptionScheduleContext } from "../../Contexts/SubscriptionSchedule";
import AddApp from "../../Screens/Admin/AddApp";
import AddCategory from "../../Screens/Admin/AddCategory";
import AddCompany from "../../Screens/Admin/AddCompany";
import AddHomepage from "../../Screens/Admin/AddHomepage";
import AddInsertedUser from "../../Screens/Admin/AddInsertedUser";
import AddPlan from "../../Screens/Admin/AddPlan";
import AddSector from "../../Screens/Admin/AddSector";
import AddSubscriptionSchedule from "../../Screens/Admin/AddSubscriptionSchedule";
import AddTag from "../../Screens/Admin/AddTag";
import Admin from "../../Screens/Admin/Admin";
import Admin1 from "../../Screens/Admin/Admin1";
import Admin4 from "../../Screens/Admin/Admin4";
import Admin5 from "../../Screens/Admin/Admin5";
import AdminUsers from "../../Screens/Admin/AdminUsers";
import EditApp from "../../Screens/Admin/EditApp";
import EditCategory from "../../Screens/Admin/EditCategory";
import EditCompany from "../../Screens/Admin/EditCompany";
import EditHomepage from "../../Screens/Admin/EditHomepage";
import EditInsertedUser from "../../Screens/Admin/EditInsertedUser";
import EditPlan from "../../Screens/Admin/EditPlan";
import EditSector from "../../Screens/Admin/EditSector";
import EditSubscriptionSchedule from "../../Screens/Admin/EditSubscriptionSchedule";
import EditTag from "../../Screens/Admin/EditTag";
import EditUser from "../../Screens/Admin/EditUser";
import HomePageList from "../../Screens/Admin/HomePageList";
import InsertedUserList from "../../Screens/Admin/InsertedUsersList";
import ListApps from "../../Screens/Admin/ListApps";
import ListCategories from "../../Screens/Admin/ListCategories";
import ListCompanies from "../../Screens/Admin/ListCompanies";
import ListPlans from "../../Screens/Admin/ListPlans";
import ListSectors from "../../Screens/Admin/ListSectors";
import ListTags from "../../Screens/Admin/ListTags";
import SubscriptionScheduleList from "../../Screens/Admin/SubscriptionScheduleList";
import AgencyHome from "../../Screens/Agency Home/AgencyHome";
import AuthContext from "../../Screens/Auth/AuthContext";
import PostSignup from "../../Screens/Auth/PostSignup";
import RequestForgotPassword from "../../Screens/Auth/RequestForgotPassword";
import ResetPassword from "../../Screens/Auth/ResetPassword";
import SignIn from "../../Screens/Auth/SignIn";
import SignUp from "../../Screens/Auth/SignUp";
import TwitterLogin from "../../Screens/Auth/TwitterLogin";
import TwitterPostSignup from "../../Screens/Auth/TwitterPostSignup";
import TwitterSignUp from "../../Screens/Auth/TwitterSignUp";
import Blog from "../../Screens/Blog/Blog";
import CategoriesIndividual from "../../Screens/Categories Individual/CategoriesIndividual";
import Categories from "../../Screens/Categories/Categories";
import CompaniesSuggested from "../../Screens/Companies_suggested/CompaniesSuggested";
import CompaniesByType from "../../Screens/CompaniesByType";
import Company from "../../Screens/Company/Company";
import Faq from "../../Screens/Faq/Faq";
import FeatureUnavailable from "../../Screens/FeatureUnavailable";
import Fundalysis from "../../Screens/Fundalysis/Fundalysis";
import HomePage from "../../Screens/Home Page/HomePage";
import ListPage from "../../Screens/List Page/ListPage";
import BlogDetails from "../../Screens/New Blog Page/BlogDetails";
import PhilosophyPage from "../../Screens/Philosophy Page/PhilosophyPage";
import PlanSubscription from "../../Screens/PlanSubscription";
import Pricing from "../../Screens/Pricing/Pricing";
import Profile from "../../Screens/Profile";
import SearchPage from "../../Screens/Search/SearchPage";
import SectorPage from "../../Screens/Sector Page/SectorPage";
import Secotrs from "../../Screens/Sectors";
import Sorry from "../../Screens/Sorry Page";
import SorryFreeCompanies from "../../Screens/SorryFreeCompanies";
import SpecialList from "../../Screens/SpecialList";
import SubscriptionOpen from "../../Screens/Subscription Open/SubscriptionOpen";
import Subscriptions from "../../Screens/Subscriptions Page/Subscriptions";
import Tag from "../../Screens/Tag";
import Tags from "../../Screens/Tags";
import TeamPage from "../../Screens/Team Page/TeamPage";
import Thank from "../../Screens/Thanks Page/Thank";
import {
  adminAddApp,
  adminAddCategory,
  adminAddCompany,
  adminAddHomepage,
  adminAddInsertedUsers,
  adminAddPlan,
  adminAddSector,
  adminAddSubscriptionSchedule,
  adminAddTag,
  adminEditApp,
  adminEditCategory,
  adminEditCompany,
  adminEditHomepage,
  adminEditInsertedUsers,
  adminEditPlan,
  adminEditSector,
  adminEditSubscriptionSchedule,
  adminEditTag,
  adminHomepage,
  adminInsertedUsers,
  adminListApps,
  adminListCategories,
  adminListCompanies,
  adminListPlans,
  adminListSectors,
  adminListSubscriptionSchedules,
  adminListTags,
  adminPanel,
  adminPanel1,
  adminPanel4,
  adminPanel5,
  adminUsers,
  agencyHome,
  alert,
  blog,
  blogFooter,
  blogWithId,
  browseFreeCompanies,
  categories,
  categoriesIndividual,
  companiesSuggested,
  companyByType,
  companyWithId,
  defaultRoute,
  editUsers,
  faq,
  featureUnavailable,
  fundalysis,
  listPage,
  login,
  loginTwitter,
  philosophyPage,
  planSubscription,
  postSignup,
  postSignupTwitter,
  pricing,
  profile,
  register,
  registerTwitter,
  requestForgotPassword,
  resetPassword,
  search,
  sectorPage,
  sectors,
  sorry,
  specialList,
  subscriptionOpen,
  subscriptions,
  table,
  tag,
  tags,
  team,
  thankYou,
  video,
  videoYoutube,
  viewMore,
  viewMoreId,
} from "../../utils/routingConstants";
import ViewMorePage from "../../ViewMore";
import AdminRoute from "../AdminRoute";
import PrivateRoute from "../PrivateRoute";
import PublicRoute from "../PublicRoute/indes";

const AuthWrapper = () => {
  const { isAuthenticated, isSubscribed, activeSubscription } =
    useContext(AuthContext);
  const history = useHistory();

  function parallaxIt(e, wrap) {
    const movement = wrap.mContent.dataset.movement || 0.2;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const boundingRect = wrap.mArea.getBoundingClientRect();
    const halfDiff = Math.abs(boundingRect.width - boundingRect.height) / 2;
    const relX = e.pageX - boundingRect.left - halfDiff;
    const relY = e.pageY - boundingRect.top;

    gsap.to(wrap.mContent, {
      x: (relX - boundingRect.width / 2) * movement,
      y: (relY - boundingRect.height / 2 - scrollTop) * movement,
      ease: "power1",
      duration: 0.6,
    });
  }

  useEffect(() => {
    const unlisten = history.listen(() => {
      magneticButtonFunc();
    });
    const magneticButtonFunc = () => {
      const mWrap = document.querySelectorAll(".magnetic-wrap");

      mWrap.forEach(function (wrap) {
        wrap.mContent = wrap.querySelector(".magnetic-content");
        wrap.mArea = wrap.querySelector(".magnetic-area");
        wrap.mArea.addEventListener("mousemove", function (e) {
          parallaxIt(e, wrap);
        });
        wrap.mArea.addEventListener("mouseleave", function () {
          gsap.to(wrap.mContent, {
            scale: 1,
            x: 0,
            y: 0,
            ease: "power3",
            duration: 0.6,
          });
        });
      });
    };
    return () => {
      unlisten();
    };
  }, [history]);
  const location = useLocation();
  if (!isAuthenticated && location && location.pathname.includes("/blog/")) {
    const blogId = location.pathname.split("/blog/")[1];
    localStorage.setItem("blogId", blogId);
  }

  const magneticButtonFunc = useCallback(() => {
    const mWrap = document.querySelectorAll(".magnetic-wrap");
    mWrap.forEach(function (wrap) {
      wrap.mContent = wrap.querySelector(".magnetic-content");
      wrap.mArea = wrap.querySelector(".magnetic-area");
      wrap.mArea.addEventListener("mousemove", function (e) {
        parallaxIt(e, wrap);
      });
      wrap.mArea.addEventListener("mouseleave", function () {
        gsap.to(wrap.mContent, {
          scale: 1,
          x: 0,
          y: 0,
          ease: "power3",
          duration: 0.6,
        });
      });
    });
  }, []);
  useEffect(() => {
    const cursor = new MouseFollower({
      container: "#container-wrapper",
      stickDelta: 0,
    });
    magneticButtonFunc();
    document.body.addEventListener("click", (e) => {
      if (e.target.id === "navBackdropSticky" || e.target.id === "stickyElem") {
        magneticButtonFunc();
      }
    });
    return () => {
      cursor?.destroy();
    };
  }, [magneticButtonFunc, location]);

  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);

  const { activeSchedule } = useContext(SubscriptionScheduleContext);

  const diffDays = useMemo(() => {
    if (activeSchedule) {
      const today = new Date();
      const diffTime = activeSchedule.endDate - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
    return -1;
  }, [activeSchedule]);

  useEffect(() => {
    if (
      activeSchedule &&
      isAuthenticated &&
      ((isSubscribed &&
        activeSubscription &&
        new Date(activeSubscription.expiresOn) <
          new Date(activeSchedule.endDate)) ||
        !isSubscribed)
    ) {
      const timeOutId = setTimeout(() => setWelcomeModalOpen(true), 5000);

      return () => clearTimeout(timeOutId);
    }
  }, [activeSchedule, isAuthenticated, isSubscribed, activeSubscription]);

  return (
    <>
      <WelcomeModal
        handleClose={() => setWelcomeModalOpen(false)}
        handleNext={() => {
          history.push(pricing);
          setWelcomeModalOpen(false);
        }}
        message={
          isSubscribed
            ? `Your subscription is about to expire. Would you like to renew it now? Renewal is open ${
                diffDays > 1
                  ? `for the next ${diffDays} days`
                  : "until tomorrow"
              }—renew today so you don’t miss out!`
            : `You're a free user. Would you like to go for a paid subscription? It’s open ${
                diffDays > 1
                  ? `for the next ${diffDays} days`
                  : "until tomorrow"
              }! Check out our plans and pricing to see what’s available.`
        }
        open={welcomeModalOpen}
        title={isSubscribed ? "Subscription Renewal" : "Free User"}
      />
      <NavBar />
      <ScrollToTop />
      <Switch>
        <Route exact={true} path={defaultRoute} component={HomePage} />
        {/* <PrivateRoute exact={true} path={pricing} component={<Pricing />} /> */}
        <Route exact={true} path={postSignup} component={PostSignup} />
        <Route exact={true} path={categories} component={Categories} />
        <Route exact={true} path={philosophyPage} component={PhilosophyPage} />
        <PublicRoute
          path={requestForgotPassword}
          component={<RequestForgotPassword />}
        />
        <PublicRoute path={resetPassword} component={<ResetPassword />} />
        <PublicRoute path={loginTwitter} component={<TwitterLogin />} />
        <PublicRoute path={registerTwitter} component={<TwitterSignUp />} />
        <PublicRoute
          path={postSignupTwitter}
          component={<TwitterPostSignup />}
        />
        <PublicRoute path={register} component={<SignUp />} />
        <PublicRoute path={login} component={<SignIn />} />
        <PrivateRoute path={team} component={<TeamPage />} />
        {/* TODO: Fix the routing issue for private active schedule rotues */}
        <PrivateRoute
          path={thankYou}
          component={
            activeSchedule ? <Thank /> : <Redirect to={subscriptions} />
          }
        />

        <PrivateRoute
          path={sorry}
          component={
            activeSchedule ? <Sorry /> : <Redirect to={subscriptions} />
          }
        />
        <PrivateRoute
          path={planSubscription}
          component={
            activeSchedule ? (
              <PlanSubscription />
            ) : (
              <Redirect to={subscriptions} />
            )
          }
        />
        <Route
          exact
          path={pricing}
          component={
            activeSchedule ? Pricing : () => <Redirect to={subscriptions} />
          }
        />

        <Route
          exact
          path={subscriptions}
          component={
            activeSchedule ? () => <Redirect to={pricing} /> : Subscriptions
          }
        />

        {/* <ActiveScheduleRoute
          path={pricing}
          shouldBeActive={true}
          component={<Pricing />}
        />
        <ActiveScheduleRoute
          shouldBeActive={false}
          path={subscriptions}
          component={<Subscriptions />}
        /> */}
        <PrivateRoute path={listPage} component={<ListPage />} />
        <PrivateRoute path={sectorPage} component={<SectorPage />} />
        <PrivateRoute path={sectors} component={<Secotrs />} />
        <PrivateRoute path={specialList} component={<SpecialList />} />
        <PrivateRoute path={companyByType} component={<CompaniesByType />} />
        <PrivateRoute path={tags} component={<Tags />} />
        <PrivateRoute path={tag} component={<Tag />} />
        <Route exact path={faq} component={Faq} />
        <PrivateRoute
          path={categoriesIndividual}
          component={<CategoriesIndividual />}
        />
        <PrivateRoute
          path={companiesSuggested}
          component={<CompaniesSuggested />}
        />
        {/*         <PrivateRoute path={dashboard} component={<Dashboard />} /> */}
        <PrivateRoute path={blogWithId} component={<BlogDetails />} />
        <PrivateRoute path={blog} component={<Blog />} />
        <PrivateRoute path={companyWithId} component={<Company />} />
        <PrivateRoute path={fundalysis} component={<Fundalysis />} />
        <PrivateRoute path={agencyHome} component={<AgencyHome />} />
        <PrivateRoute path={search} component={<SearchPage />} />
        <PrivateRoute path={blogFooter} component={<BlogFooter />} />
        <PrivateRoute path={profile} component={<Profile />} />
        <PrivateRoute path={viewMore} component={<ViewMorePage />} />
        <PrivateRoute path={viewMoreId} component={<ViewMorePage />} />
        <PrivateRoute path={table} component={<CustomTable />} />
        <PrivateRoute path={alert} component={<Alert />} />
        <PrivateRoute path={videoYoutube} component={<YouTubeVideo />} />
        <PrivateRoute path={video} component={<Video />} />

        <AdminRoute
          path={adminListSubscriptionSchedules}
          component={<SubscriptionScheduleList />}
        />
        <AdminRoute
          path={adminAddSubscriptionSchedule}
          component={<AddSubscriptionSchedule />}
        />
        <AdminRoute
          path={adminEditSubscriptionSchedule}
          component={<EditSubscriptionSchedule />}
        />
        <AdminRoute path={adminPanel1} component={<Admin1 />} />
        <AdminRoute path={adminAddCompany} component={<AddCompany />} />
        <AdminRoute path={adminEditCompany} component={<EditCompany />} />
        <AdminRoute path={adminListCompanies} component={<ListCompanies />} />
        <AdminRoute path={adminUsers} component={<AdminUsers />} />
        <AdminRoute path={editUsers} component={<EditUser />} />
        <AdminRoute path={adminHomepage} component={<HomePageList />} />
        <AdminRoute path={adminEditHomepage} component={<EditHomepage />} />
        <AdminRoute path={adminAddHomepage} component={<AddHomepage />} />
        <AdminRoute path={adminListTags} component={<ListTags />} />
        <AdminRoute path={adminAddTag} component={<AddTag />} />
        <AdminRoute path={adminEditTag} component={<EditTag />} />
        <AdminRoute path={adminListSectors} component={<ListSectors />} />
        <AdminRoute path={adminAddSector} component={<AddSector />} />
        <AdminRoute path={adminEditSector} component={<EditSector />} />
        <AdminRoute path={adminListCategories} component={<ListCategories />} />
        <AdminRoute path={adminAddCategory} component={<AddCategory />} />
        <AdminRoute path={adminEditCategory} component={<EditCategory />} />
        <AdminRoute path={adminListApps} component={<ListApps />} />
        <AdminRoute path={adminAddApp} component={<AddApp />} />
        <AdminRoute path={adminEditApp} component={<EditApp />} />
        <AdminRoute
          path={adminInsertedUsers}
          component={<InsertedUserList />}
        />
        <AdminRoute
          path={adminEditInsertedUsers}
          component={<EditInsertedUser />}
        />
        <AdminRoute
          path={adminAddInsertedUsers}
          component={<AddInsertedUser />}
        />

        <AdminRoute path={adminListPlans} component={<ListPlans />} />
        <AdminRoute path={adminAddPlan} component={<AddPlan />} />
        <AdminRoute path={adminEditPlan} component={<EditPlan />} />
        <PrivateRoute
          path={subscriptionOpen}
          component={<SubscriptionOpen />}
        />
        <AdminRoute path={adminPanel} component={<Admin />} />
        <AdminRoute path={adminPanel4} component={<Admin4 />} />
        <AdminRoute path={adminPanel5} component={<Admin5 />} />
        <PrivateRoute
          path={browseFreeCompanies}
          component={<SorryFreeCompanies />}
        />
        <PrivateRoute
          path={featureUnavailable}
          component={<FeatureUnavailable />}
        />

        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
};

export default AuthWrapper;
