import React from "react";

const RightArrowIcon = ({ disabled }) => {
  return (
    <svg
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2727 1.687C15.1824 1.59153 15.1119 1.47923 15.0651 1.3565C15.0183 1.23377 14.9961 1.10302 14.9998 0.971721C15.0035 0.840419 15.0331 0.711134 15.0867 0.591248C15.1404 0.471362 15.2172 0.363223 15.3127 0.273005C15.4081 0.182787 15.5204 0.112257 15.6432 0.0654411C15.7659 0.0186256 15.8966 -0.00355848 16.0279 0.000155861C16.1592 0.00387021 16.2885 0.03341 16.4084 0.0870888C16.5283 0.140767 16.6364 0.217534 16.7267 0.313005L25.2267 9.313C25.4022 9.49867 25.5 9.74449 25.5 10C25.5 10.2555 25.4022 10.5013 25.2267 10.687L16.7267 19.688C16.637 19.7856 16.5289 19.8643 16.4086 19.9198C16.2883 19.9752 16.1581 20.0062 16.0257 20.0109C15.8933 20.0156 15.7613 19.9939 15.6374 19.9472C15.5134 19.9004 15.4 19.8295 15.3037 19.7386C15.2073 19.6476 15.1301 19.5384 15.0763 19.4173C15.0225 19.2963 14.9933 19.1657 14.9905 19.0333C14.9876 18.9008 15.011 18.7691 15.0595 18.6458C15.1079 18.5225 15.1804 18.4101 15.2727 18.315L23.1247 10L15.2727 1.687Z"
        fill={disabled ? "#7C7C7C" : "currentColor"}
      />
      <path
        d="M1 10.3501H24"
        stroke={disabled ? "#7C7C7C" : "currentColor"}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RightArrowIcon;
