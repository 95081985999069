import { createTheme } from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAppSelector } from "../../../store/hooks";
const useEditUser = () => {
  const { id } = useParams();
  const token = localStorage.getItem("code");
  const [user, setUser] = useState();
  const [accounts, setAccounts] = useState();
  const [subscriptions, setSubscriptions] = useState();
  const [refs, setRefs] = useState();
  const [gstCredentials, setGstCredentials] = useState();
  const fetchUser = useCallback(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}user/full-user/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          const { referrals, Account, subscription, ...rest } = res.data;
          setUser(rest);
          setAccounts(Account);
          setSubscriptions(subscription);
          setRefs(referrals);
        })
        .catch((e) => console.error("Error fetching user:", e));
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, [id, token]);
  const fetchUserGstCredentials = useCallback(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}user/gst-credentials/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          setGstCredentials(res.data);
        })
        .catch((e) => console.error("Error fetching GST credentials:", e));
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, [id, token]);
  useEffect(() => {
    fetchUser();
    fetchUserGstCredentials();
  }, [fetchUser, fetchUserGstCredentials]);
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const [showAccounts, setShowAccounts] = useState(false);
  const [showSubscriptions, setShowSubscriptions] = useState(true);
  const [showReferrals, setShowReferrals] = useState(false);
  const [showGst, setShowGst] = useState(false);

  return {
    user,
    accounts,
    subscriptions,
    themeProv,
    showAccounts,
    setShowAccounts,
    showSubscriptions,
    setShowSubscriptions,
    fetchUser,
    showReferrals,
    setShowReferrals,
    refs,
    showGst,
    setShowGst,
    gstCredentials,
    fetchUserGstCredentials,
  };
};
export default useEditUser;
