import { Grid, Typography } from "@mui/material";
import React from "react";
import AnimatingNumber from "../../../../Components/AnimatingNumber/animation-number";

const LatestIdeaCardDescription = ({
  mediumHeaderFontSize,
  latestIdea,
  smallParagraphFontSize,
  dateOptions,
  paragraphFontSize,
}) => {
  return (
    <Grid container display="flex" flexDirection="column" spacing={2}>
      <Grid item>
        <Typography
          fontWeight={600}
          lineHeight={1}
          fontSize={mediumHeaderFontSize}
          textTransform="capitalize"
        >
          {latestIdea.name}
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          display="flex"
          justifyContent="start"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography
              fontSize={smallParagraphFontSize}
              className={latestIdea.change >= 0 ? "text-green" : "text-red"}
              display="flex"
            >
              ₹{latestIdea.price}
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={smallParagraphFontSize}>
              {latestIdea.change >= 0 ? "+" : "-"}INR
            </Typography>
          </Grid>
          <Grid item display="flex" fontSize={smallParagraphFontSize}>
            ₹
            <AnimatingNumber value={latestIdea.change} />
          </Grid>
          <Grid item display="flex" fontSize={smallParagraphFontSize}>
            (₹
            <AnimatingNumber value={latestIdea.percent_change} />
            %)
          </Grid>
          <Grid item display="flex">
            <Typography
              fontSize={smallParagraphFontSize}
              lineHeight={1}
              textTransform="capitalize"
            >
              {new Date(latestIdea?.valueDate).toLocaleDateString(
                "en-US",
                dateOptions,
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          fontWeight={300}
          lineHeight={1.2}
          textTransform="capitalize"
          fontSize={paragraphFontSize}
        >
          {latestIdea.description}
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontWeight={600} lineHeight={1} textTransform="capitalize">
          published on{" "}
          {new Date(latestIdea?.date).toLocaleDateString("en-US", dateOptions)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LatestIdeaCardDescription;
