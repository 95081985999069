import React from "react";

const PhonePaySVG = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.0052 47.3957C37.3741 47.3957 47.401 37.3687 47.401 24.9998C47.401 12.631 37.3741 2.604 25.0052 2.604C12.6363 2.604 2.60938 12.631 2.60938 24.9998C2.60938 37.3687 12.6363 47.3957 25.0052 47.3957Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.6328 8.854L30.1034 19.6157"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7812 19.6157H36.205"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.5178 41.1457C30.0554 31.7641 30.1115 19.6157 30.1115 19.6157"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.7509 19.6157C19.7509 22.8735 19.627 27.8761 19.627 29.0107C19.6071 29.6654 19.7332 30.3163 19.9963 30.9161C20.2594 31.5159 20.6528 32.0496 21.148 32.4783C21.6431 32.907 22.2276 33.2201 22.8589 33.3946C23.4901 33.5692 24.1524 33.6009 24.7975 33.4875C26.6308 33.2593 28.4405 32.87 30.2056 32.3242"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PhonePaySVG;
