import { Box, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../../../Components/Button/Button";
import { useAppSelector } from "../../../../store/hooks";

const OverlayShade = ({ isAuthenticated, children }) => {
  const history = useHistory();
  const darkMode = useAppSelector((store) => store.theme.dark);
  return (
    <Box position="relative" height={"100%"} display="flex" flex={1}>
      {children}

      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={
          darkMode ? "rgba(121, 121, 121, 0.2)" : "rgba(255, 255, 255, 0.2)"
        }
        zIndex={1}
        sx={{
          pointerEvents: "none",
          backdropFilter: "blur(10px)",
          borderRadius: "16px",
        }}
      >
        <Box textAlign="center" style={{ pointerEvents: "auto" }}>
          <Typography mb={4} variant="h6" gutterBottom>
            {!isAuthenticated
              ? "Login to discover our content"
              : "Subscribe to view content"}
          </Typography>
          <Button
            onClick={
              isAuthenticated
                ? () => history.push("/subscriptions")
                : () => history.push("/login")
            }
          >
            {isAuthenticated ? "Subscribe" : "Login"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OverlayShade;
